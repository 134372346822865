body {
    font-family: $font;
}

button,
input,
select {
    font-family: $font;
}

h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

a,
a:hover,
a:focus {
    outline: none;
    text-decoration: none;
    color: $primaryColor;
}

a:hover {
    color: $primaryColor;
}

button {
    outline: none;
}

.content-title {
    display: block;
    margin-bottom: 30px;
    .content-title-area {
        display: inline-block;
        .heading {
            text-shadow: 1px -18px 0px #f8f8f8;
            display: block;
            font-size: 48px;
            line-height: 48px;
            font-weight: 400;
            color: $primaryDarkColor;
            margin-bottom: 30px;
            span{font-weight: 600}
            @include screen('mobile') {
                font-size: 18px;
                line-height: 24px;
            }
            @include screen('tablet') {
                font-size: 22px;
                line-height: 26px;
            }
            @include screen('laptop') {
                font-size: 22px;
                line-height: 26px;
            }
        }
    }
}

.page-block{
    margin-bottom: 60px;
    .page-block-title{
        display: block;
        color: $primaryColor;
        font-weight: 700;
        font-size: 26px;
        margin-bottom: 30px;
        @include screen('mobile'){
            font-size: 16px;
        }
        &.min{
            margin-bottom: 10px;
        }
    }

    .page-block-description {
        display: block;
        color: #333;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 10px;

        @include screen('mobile') {
            font-size: 16px;
        }
        ul{
            list-style: square;
            margin-left: 20px;
            li{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .widget{
        margin-bottom: 30px;
        overflow: hidden;
        display: block;
        &.photo-gallery,
        &.menu-gallery{
            .item {
                margin-bottom: 30px;
                .item-container {
                    .picture {
                        background-color: #000;
                        img {
                            width: 100%;
                            height: auto;
                            border-radius: 5px;
                        }
                        &:before {
                            border-radius: 4px;
                            content: '';
                            position: absolute;
                            left: 15px;
                            right: 15px;
                            top: 0;
                            bottom: 0;
                            background: rgba(0, 0, 0, 0.4);
                            z-index: 1;
                            @include opacity(0);
                            transition: all 300ms ease;
                        }
                        &:after {
                            font-family: 'FontAwesome';
                            font-size: 18px;
                            content: "\f002";
                            width: 50px;
                            height: 50px;
                            text-align: center;
                            line-height: 50px;
                            color: #fff;
                            transition: all 300ms ease;
                            z-index: 2;
                            @include center(both);
                            @include opacity(0);
                        }
                        .text {
                            padding: 0 20px;
                            @include opacity(0);
                            z-index: 222;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 160px;
                            display: block;
                            width: 100%;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 500;
                            color: #fff;
                        }
                    }
                    &:hover {
                        .picture {
                            &:before,
                            &:after {
                                @include opacity(1)
                            }
                        }
                        .text {
                            @include opacity(1)
                        }
                    }
                }
            }
        }
        &.company-about{
            .img{
                margin-bottom: 20px;
                img{
                    width: 100%;
                }
            }
            p{
                overflow: hidden;
                margin-bottom: 10px;
            }
            a{
                transition: all 300ms ease;
                display: inline-block;
                float: right;
                font-weight: 600;
                color: $primaryColor;
                &:hover{
                    color: $primaryLightColor;
                }
            }
        }
        &.widget-reklam {
            .img {
                margin-bottom: 20px;

                img {
                    width: 100%;
                }
            }

            p {
                overflow: hidden;
                margin-bottom: 10px;
                font-size:18px;
            }

            a {
                transition: all 300ms ease;
                display:block;
                font-weight: 600;
                color: #333;

                &:hover {
                    color: $primaryColor;
                }
            }
        }
        &.company-location{
            .map-block {
                overflow: hidden;
                border: 1px solid #eee;
                height: 300px;
                #map_canvas {
                    width: 100%;
                    height: 300px;
                }
            }
            .mapInfo {
                text-align: center;
                display: inline-block;
                img {
                    width: 50%;
                    display: inline-block;
                }
            }
        }
        &.company-coupon{
            .company-coupon-code{
                background-color: $primaryLightColor;
                position: relative;
                height: 160px;
                padding: 10px 0 0 0;
                overflow: hidden;
                border-radius: 6px;
                span,strong{
                    color: #fff;
                    display: block;
                    text-align: center;
                    font-size: 28px;
                    position: relative;
                    z-index: 2;
                }
                .code{
                    position: absolute;
                    z-index: 1;
                    left:0; bottom:0; right: 0;
                    width: 100%;
                    background: $primaryColor url("../images/coupon_bg.png");
                    background-position: top center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    padding: 40px 0 0 0;
                    display: block;
                    text-align: center;
                    color: #fff;
                    font-size: 27px;
                    height: 85px;
                    font-weight: 600;
                }
            }
        }
        &.company-promotion, &.company-news{
            .item {
                margin-bottom: 30px;
                .item-container {
                    overflow: hidden;
                    border-radius: 5px;
                    background-color: $primaryColor;
                    transition: all 300ms ease;
                    display: inline-block;
                    width: 100%;
                    position: relative;
                    .col-xs-12 {
                        &:first-child {
                            padding-right: 0;
                        }
                        &:last-child {
                            padding-left: 0;
                        }
                    }
                    .picture-parent{
                        position: relative;
                        height: 210px;
                        .picture {
                            img {
                                width: 100%;
                                height: 210px;
                            }
                        }
                    }
                    .content {
                        color: #fff;
                        position: relative;
                        height:  180px;
                        transition: all 300ms ease;
                        .content-area {
                            margin: 20px 20px;
                            .name {
                                max-height: 50px;
                                overflow: hidden;
                                display: block;
                                font-size: 18px;
                                line-height: 26px;
                                font-weight: 700;
                                margin-bottom: 10px;
                                transition: all 300ms ease;
                                &:hover {
                                    color: $primaryDarkColor;
                                }
                            }
                            p {
                                margin-top: 10px;
                                margin-bottom: 10px;
                                font-size: 14px;
                                line-height: 24px;
                                overflow: hidden;
                                min-height: 20px;
                                max-height: 78px;
                                padding-bottom: 10px;
                                border-bottom: 1px solid rgba(255,255,255,0.1);
                            }
                            .link {
                                float: right;
                                transition: all 300ms ease;
                                display: inline-block;
                                color: #fff;
                                line-height: 30px;
                                font-size: 12px;
                                text-align: right;
                                font-weight: 700;
                                text-transform: uppercase;
                                &:hover {
                                    color:  rgba(255,255,255,0.6);
                                }
                            }
                        }
                    }
                    @include screen('mobile'){
                        .picture-parent{
                            height: auto;
                            .picture{
                                height: auto;
                                img{
                                    height: auto;
                                }
                            }
                        }
                        .content{
                            height: auto;
                            .content-area{
                                margin: 30px;
                            }
                        }
                    }
                    @include screen('tablet'){
                        .picture-parent{
                            height: auto;
                            .picture{
                                height: auto;
                                img{
                                    height: auto;
                                }
                            }
                        }
                        .content{
                            height: auto;
                            .content-area{
                                height: 150px;
                                margin: 30px;
                            }
                        }
                    }
                }
                &:nth-child(2),&:nth-child(3){
                    .item-container{
                        background-color: $primaryLightColor;
                    }
                }
            }
        }
        &.company-news{
            .item{
                .item-container{
                    background-color: #fafafa;
                    border: 1px solid #ddd;
                    .content{
                        .content-area{
                            .name{
                                color: $primaryColor;
                            }
                            p{
                                color: $primaryDarkColor;
                                border-bottom-color: #ddd;
                            }
                            .link{
                                color: $primaryLightColor;
                                &:hover{
                                    color: $primaryDarkColor;
                                }
                            }
                        }
                    }
                }
                &:nth-child(2),&:nth-child(3){
                    .item-container{
                        background-color: #fafafa;
                    }
                }
            }
        }
        &.company-branch-offices{
            .item{
                @include screen('mobile'){
                    margin-bottom: 30px;
                }
                @include screen('tablet'){
                    margin-bottom: 30px;
                }
                .item-container{
                    text-align: center;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    padding: 20px 10px;
                    height: 230px;
                    .icon{
                        color: $primaryLightColor;
                        font-size: 32px;
                        line-height: 32px;
                        margin-bottom: 20px;
                    }
                    .title{
                        font-size: 18px;
                        color: $primaryColor;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    .address{
                        color: #8d8d8d;
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    .phones{
                        ul{
                            li{
                                margin-bottom: 5px;
                                a{
                                    color: $primaryColor;
                                    font-size: 16px;
                                    font-weight: 500;
                                    transition: all 300ms ease;
                                    &:hover{
                                        color: $primaryLightColor;
                                    }
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.company-events{
            .item {
                .item-container {
                    @include screen('mobile'){
                        margin: 0 40px;
                    }
                    @include screen('tablet'){
                        margin: 0 20px;
                    }
                    position: relative;
                    .content {
                        .picture {
                            margin-bottom: 10px;
                            display: block;
                            text-align: center;
                            img {
                                border-radius: 5px;
                            }
                        }
                        .name-brand-area {
                            text-align: center;
                            transition: all 500ms ease;
                            .name-brand {
                                position: relative;
                                .name {
                                    display: block;
                                    text-transform: uppercase;
                                    font-size: 18px;
                                    line-height: 24px;
                                    font-weight: 600;
                                    color: $primaryDarkColor;
                                    transition: all 500ms ease;
                                    &:hover{
                                        color: $primaryLightColor;
                                    }
                                }
                                p {
                                    max-height: 60px;
                                    overflow: hidden;
                                    margin-top: 20px;
                                    transition: all 500ms ease;
                                    color: $primaryDarkColor;
                                    font-style: oblique;
                                    padding: 0 15px;
                                    font-size: 14px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
            .owl-dots {
                margin-top: 30px;
                .owl-dot {
                    span {
                        background-color: #ddd;
                        width: 14px;
                        height: 14px;
                        margin: 0 20px 0 0;
                    }
                    &.active {
                        span {
                            background-color: $primaryColor;
                        }
                    }
                    &:hover {
                        span {}
                    }
                    &:last-child {
                        margin: 0;
                    }
                }
            }
            @include screen('mobile') {
                padding: 0;
                .item {
                    .item-container {
                        .content {
                            .name-brand-area {
                                .name-brand {
                                    .name {
                                        font-size: 16px;
                                        line-height: 16px;
                                    }
                                    .brand {
                                        font-size: 14px;
                                        line-height: 14px;
                                    }
                                    p {
                                        max-height: 140px;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @include screen('tablet') {
                padding: 0;
                .item {
                    .item-container {
                        .content {
                            .name-brand-area {
                                .name-brand {
                                    .name {
                                        font-size: 16px;
                                        line-height: 16px;
                                    }
                                    .brand {
                                        font-size: 14px;
                                        line-height: 14px;
                                    }
                                    p {
                                        max-height: 126px;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @include screen('laptop') {
                padding: 0;
                .item {
                    .item-container {
                        .content {
                            .name-brand-area {
                                .name-brand {
                                    .name {
                                        font-size: 16px;
                                        line-height: 16px;
                                    }
                                    .brand {
                                        font-size: 14px;
                                        line-height: 14px;
                                    }
                                    p {
                                        max-height: 126px;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.news-detail{
        .date-title{
            overflow: hidden;
            margin-bottom: 30px;
            .date{
                text-transform: uppercase;
                width: 15%;
                display: inline-block;
                float:left;
                overflow: hidden;
                position: relative;
                border-radius: 5px;
                span{
                    display: block;
                    text-align: center;
                    color: #fff;
                    font-weight: 600;
                    &:first-child{
                        height: 80px;
                        font-size: 40px;
                        line-height: 80px;
                        background-color: $primaryColor;
                    }
                    &:last-child{
                        height: 40px;
                        line-height: 40px;
                        font-size: 14px;
                        background-color: $primaryLightColor;
                    }
                }
                @include screen('mobile'){
                    width: 30%;
                   span{
                       &:first-child{
                           height: 40px;
                           font-size: 24px;
                           line-height: 40px;
                           background-color: $primaryColor;
                       }
                       &:last-child{
                           height: 30px;
                           line-height: 30px;
                           font-size: 14px;
                           background-color: $primaryLightColor;
                       }
                   }
                }
                @include screen('tablet'){
                    width: 20%;
                    span{
                        &:first-child{
                            height: 60px;
                            font-size: 30px;
                            line-height: 60px;
                            background-color: $primaryColor;
                        }
                        &:last-child{
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            background-color: $primaryLightColor;
                        }
                    }
                }
            }
            .title{
                width: 85%;
                display: inline-block;
                float:left;
                span{
                    display: inline-block;
                    float:left;
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 10px;
                    margin-left: 30px;
                    font-size: 40px;
                    color: $primaryDarkColor;
                    font-weight: 600;
                }
                @include screen('mobile'){
                    width: 70%;
                    span{
                        font-size: 16px;
                    }
                }
                @include screen('tablet'){
                    width: 80%;
                    span{
                        font-size: 28px;
                    }
                }
            }
        }
        .picture{
            margin-bottom: 30px;
            text-align: center;
            img{
                display: inline-block;
            }
        }
        .content{
            h1,h2,h3{
                font-weight: 600;
                font-size: 24px;
                margin-bottom: 15px;
                color: $primaryColor;
            }
            p{
                font-weight: 400;
                color: $primaryDarkColor;
                margin-bottom: 15px;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .comments{
            margin-bottom: 60px;
            ul.nav{
                border:0;
                &.nav-tabs{
                    border-bottom: 4px solid $primaryLightColor;
                    li{
                        margin-bottom: 0;
                        a{
                            border-radius: 0;
                            border:0;
                            font-size: 20px;
                            font-weight: 400;
                            background-color: #ebebeb;
                            color: #666;
                            padding: 20px 40px;
                            transition: all 300ms ease;
                            &:hover{
                                background-color: $primaryLightColor;
                                color:#fff;
                            }
                        }
                        &.active{
                            a{
                                background-color: $primaryColor;
                                color: #fff;
                                &:Hover{
                                    background-color: $primaryLightColor;
                                    color:#fff;
                                }
                            }
                        }
                        @include screen('mobile'){
                            margin: 0;
                            padding: 0;
                            float:left;
                            width: 50%;
                            a{
                                width: 100%;
                                display: inline-block;
                                padding: 10px 0;
                                text-align: center;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .tab-content{
                padding-top: 30px;
                .comment{
                    display: block;
                    overflow: hidden;
                    margin-bottom: 20px;
                    border-bottom: 1px solid #eee;
                    .left{
                        display: inline-block;
                        float:left;
                        width: 10%;
                        .thumbnail{
                            border-color: #eee;
                        }
                    }
                    .right{
                        padding-left: 20px;
                        display: inline-block;
                        float:left;
                        width: 90%;
                        .panel{
                            box-shadow: none;
                            .panel-heading{
                                padding:0;
                                margin-bottom: 10px;
                                strong{
                                    font-weight: 600;
                                    color: $primaryColor;
                                }
                                .text-muted{
                                    font-weight: 600;
                                    color: $primaryLightColor;
                                }
                            }
                            .panel-body{
                                padding:0;
                            }
                        }
                    }
                    &:last-child{
                        border-bottom: 0;
                        margin-bottom: 0;
                    }
                    @include screen('mobile'){
                        .left{
                            width: 20%;
                        }
                        .right{
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
}

.gallery-list{
    .item{
        margin-bottom: 60px;
        .item-picture{
            display: block;
            text-align: center;
            margin-bottom: 30px;
            img{
                display: inline-block;
            }
        }
        .item-description {
            p{
                font-size: 18px;
                line-height: 28px;
                font-weight: 400;
            }
        }
        .item-number-share-contaniner{
            display: block;
            text-align: center;
            position: relative;
            .item-share{
                background: #fff;
                padding: 15px;
                display: inline-block;
            }
            .item-number{
                right: 0;
                @include center(vertical);
                font-size: 32px;
                line-height: 60px;
                font-weight: 700;
                background-color: #f2f2f2;
                color: #222;
                text-align: center;
                width: 60px;
                height: 60px;
                display: inline-block;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 30px;
                width: 100%;
                height: 1px;
                background-color: #eee;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.contact-info{
    margin-bottom: 60px;
    @include screen('mobile'){
        margin-bottom: 30px;
    }
    .item{
        @include screen('mobile'){
            margin-bottom: 30px;
        }
        .item-container{
            text-align: center;
            .item-icon{
                display: inline-block;
                width: 70px;
                height: 70px;
                line-height: 70px;
                border-radius: 100%;
                margin-bottom: 20px;
                border: 2px solid $primaryColor;
                i{
                    color: $primaryLightColor;
                    font-size: 25px;
                    line-height: 25px;
                }
            }
            .item-content{
                font-weight: 500;
                font-size: 16px;
                color: $primaryDarkColor;
                text-transform: uppercase;
                .list{
                    li{
                        margin-bottom: 10px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
                a{
                    color: $primaryDarkColor;
                    transition: all 300ms ease;
                    &:hover{
                        color: $primaryLightColor;
                    }
                }
            }
        }
    }
}
.map-section {
    @include screen('mobile'){
        margin-bottom: 30px;
    }
    .map-block {
        overflow: hidden;
        border: 1px solid #eee;
        height: 500px;
        #map_canvas {
            width: 100%;
            height: 500px;
        }
    }
    .mapInfo {
        text-align: center;
        display: inline-block;
        img {
            width: 50%;
            display: inline-block;
        }
    }
}

.form-control{
    border-radius: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-family: $font;
    &:focus,&:active{
        box-shadow: none;
        border-color: $primaryLightColor;
    }
}
.form-group{
    margin-bottom: 20px;
}

.page-title-breadcrumbs{
    margin-top: -50px;
    position: relative;
    padding: 140px 0;
    text-align: center;
    margin-bottom: 60px;
    background-color: #94b0d1;
    @include screen('mobile'){
        padding: 60px 0 30px 0;
        margin-bottom: 30px;
    }
    @include screen('tablet'){
        padding: 60px 0 30px 0;
        margin-bottom: 30px;
    }
    @include screen('laptop'){
        padding: 100px 0;
        margin-bottom: 30px;
    }
    .page-breadcrumbs{
        text-align: center;
        .breadcrumb{
            display: inline-block;
            background-color: transparent;
            padding: 0;
            li{
                font-size: 12px;
                font-weight: 400;
                text-transform: uppercase;
                color: #fff;
                a{
                    color: #fff;
                    transition: all 300ms ease;
                    &:hover{
                        color: $primaryColor;
                    }
                }
                & + li{
                    &:before{
                        color: #fff;
                    }
                }
            }
        }
    }
    .page-title{
        color: #fff;
        font-size: 26px;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        @include screen('mobile'){
            font-size: 14px;
            letter-spacing: 0;
        }
        @include screen('tablet'){
            font-size: 18px;
            letter-spacing: 0;
        }
        @include screen('laptop'){
            font-size: 20px;
            letter-spacing: 0;
        }
    }
    .company-bread-contact{
        font-size: 20px;
        color: #fff;
        font-weight: 500;
        margin-top: 30px;
        margin-bottom: 30px;
        text-transform: uppercase;
        .company-adress{
            margin-bottom: 30px;
            text-align: center;
        }
        .item{
            @include screen('mobile'){
                margin-bottom: 30px;
            }
            .company-contact-list{
                font-size: 15px;
                font-weight: 500;
                .contact-list-title{
                    text-align: left;
                    padding-left: 40px;
                    margin-bottom: 10px;
                }
                .contact-list{
                    ul{
                        li{
                            margin-bottom: 5px;
                            color: #fff;
                            text-align: left;
                            line-height: 30px;
                            font-size: 16px;
                            i{
                                display: inline-block;
                                float:left;
                                width: 40px;
                                line-height: 30px;
                                font-size: 24px;
                                text-align: center;
                            }
                            a{
                                display: inline-block;
                                color: #fff;
                                line-height: 30px;
                                transition: all 300ms ease;
                                &:hover{
                                    color: $primaryColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.company{
        padding: 70px 0 40px 0;
        .page-title{
            margin-bottom: 30px;
        }
    }
}

.pagination{
    li{
        margin-right: 10px;
        display: inline-block;
        a{
            border-radius: 5px;
            font-size: 14px;
            width: 50px;
            height: 40px;
            line-height: 36px;
            padding: 0;
            font-weight: 600;
            box-sizing: border-box;
            border: 2px solid #ddd;
            margin-left: 0;
            color: $primaryLightColor;
            transition: all 300ms ease;
            &:hover{
                background-color: $primaryLightColor;
                color: #fff;
                border-color: $primaryLightColor;
            }
        }
        &.active{
            a{
                background-color: $primaryColor;
                color: #fff;
                border-color: $primaryColor;
                &:hover{
                    background-color: $primaryLightColor;
                    color: #fff;
                    border-color: $primaryLightColor;
                }
            }
        }
        &:last-child{
            margin-right: 0;
        }
    }
}

.show-all{
    width: auto;
    margin:0 auto;
    text-align: center;
    a{
        display: inline-block;
        padding: 10px 40px;
        border-radius: 60px;
        border: 1px solid $primaryLightColor;
        color: $primaryColor;
        transition: all 300ms ease;
        &:hover,&:focus{
            background-color: $primaryLightColor;
            color: #fff;
        }
    }
}

.back-image {
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}

#header{
    position: relative;
    z-index: 7;
    .top-bar{
        background-color: #fff;
        padding: 40px 0 40px 0;
        @include screen('mobile'){
            padding: 20px 0;
        }
        @include screen('tablet'){
            padding: 20px 0;
        }
        .logo{

            @include screen('tablet'){
                margin-bottom: 10px;
                float: none;
                text-align: center;
                a{
                    display: inline-block;
                }
            }
        }
        .search-form{
            position: relative;
            .search-input{
                padding: 0 40px 0 30px;
                border: 1px solid #ddd;
                border-radius: 40px;
                height: 38px;
                width: 100%;
                font-weight: 500;
                font-size: 14px;
                line-height: 38px;
                &:focus{
                    outline: none;
                }
                @include screen('mobile'){
                    padding: 0 130px 0 30px;
                }
                @include screen('tablet'){
                    padding: 0 130px 0 30px;
                }
            }

            .search-btn{
                position: absolute;
                right: 0;
                top:0;
                user-select: none;
                border-radius: 40px;
                font-weight: 600;
                height: 38px;
                font-size: 14px;
                line-height: 38px;
                padding: 0 20px;
                background-color: $primaryLightColor;
                color: #fff;
                transition:  all 300ms ease;
                box-shadow: 0px 0px 21px 4px rgba(255,86,25,0.4);
                &:focus,&:hover{
                    background-color: $primaryColor;
                    box-shadow: 0px 0px 10px 2px rgba(48, 63, 159,0.4);
                }
            }

            &:before{
                content :'\f041';
                font-family: FontAwesome;
                position: absolute;
                left: 14px;
                top: 7px;
                color: #cbcbcb;
                z-index: 3;
                font-size: 18px;
            }
        }
        .social{
            float: right;
            li{
                float:left;
                margin-right: 20px;
                a{
                    color: #999;
                    font-size: 20px;
                    line-height: 38px;
                    transition:  all 300ms ease;
                    &:hover{
                        color: $primaryColor;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
            @include screen('mobile'){
                margin-top: 30px;
                float: none;
                text-align: center;
                ul{
                    display: inline-block;
                }
            }
        }

    }
    .menu-bar-area{
        position: relative;
        @include screen('mobile'){
            .dropdown-menu{
                li{
                    a{
                        color: #fff;
                        padding: 10px;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
            }
        }
        .navbar-default{
            border-radius: 4px;
            min-height: 65px;
            background-color: $primaryColor;
            background-image: url("../images/menu_bg.png");
            background-repeat: no-repeat;
            background-position: center center;
            box-shadow: 0px 0px 10px 2px rgba(48, 63, 159,0.4);
            border: 0;
            @include screen('mobile'){
                background-image: none;
                min-height: auto;
                .navbar-toggle{
                    border-color: #fff;
                    color: #fff;
                    transition: all 300ms ease;
                    box-shadow: 0px 0px 21px 4px rgba(255,255,255,0.2);
                    &:focus, &:hover{
                        background-color: $primaryLightColor;
                        border-color: $primaryLightColor;
                        box-shadow: 0px 0px 21px 4px rgba(255,86,25,0.4);
                    }
                }
            }
        }
        .container-fluid{
            padding-left: 0;
            padding-right: 0;
            @include screen('mobile'){
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .navbar-collapse{
            padding-left: 0;
            padding-right: 0;
            @include screen('mobile'){
                padding-left: 15px;
                padding-right: 15px;
                box-shadow: none;
                border: 0;
            }
        }

        .navbar-nav{
            width: 100%;
            position: relative;
            @include screen('mobile'){
                margin: 0;
            }
            > li{
                margin-right: 20px;
                @include screen('mobile'){
                    margin-right: inherit;
                }
                @include screen('tablet'){
                    margin-right: 5px;
                }
                @include screen('laptop'){
                    margin-right: 10px;
                }
                > a{
                    padding: 0 5px;
                    height: 65px;
                    font-size: 15px;
                    line-height: 65px;
                    color: #fff;
                    font-weight: 400;
                    text-transform: uppercase;
                    border-bottom:2px solid transparent;
                    transition: all 300ms ease;
                    &:hover{
                        border-bottom-color:$primaryLightColor;
                    }
                    @include screen('mobile'){
                        line-height: inherit;
                        padding: 10px;
                        font-weight: 600;
                        font-size: 16px;
                    }
                    @include screen('tablet'){
                        font-size: 11px;
                        font-weight: 600;
                    }
                    @include screen('laptop'){
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
                &:first-child{
                    margin-left: 15px;
                    @include screen('mobile'){
                        margin-left: inherit;
                        margin-top: 10px;
                    }
                    @include screen('tablet'){
                        margin-left: 5px;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                &.company-save{
                    position: absolute;
                    right: 0;
                    a{
                        width: 133px;
                        height: 65px;
                        border-radius: 0 2px 2px 0;
                        position: relative;
                        padding-top: 10px;
                        line-height: inherit;
                        text-align: center;
                        background-color: $primaryLightColor;
                        &:before{
                            content: '';
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-bottom: 65px solid $primaryLightColor;
                            border-left: 80px solid transparent;
                            left: -80px;
                            top: 0;
                        }
                        &:hover{}
                    }
                    @include screen('mobile'){
                        left:0; right: 0; bottom: 10px;
                        a{
                            width: 100%;
                            padding-top: 0;
                            line-height: 65px;
                            font-weight: 600;
                            &:before{
                                display: none;
                            }
                        }
                    }
                    @include screen('tablet'){
                        a{
                            width: 70px;
                            padding-top: 20px;
                            &:before{
                                border-bottom: 65px solid $primaryLightColor;
                                border-left: 20px solid transparent;
                                left: -20px;
                            }
                        }
                    }
                    @include screen('laptop'){
                        a{
                            width: 90px;
                            padding-top: 15px;
                            &:before{
                                border-bottom: 65px solid $primaryLightColor;
                                border-left: 20px solid transparent;
                                left: -20px;
                            }
                        }
                    }
                }
            }
            > .open{
                a, a:focus,a:hover{
                    border-bottom-color:$primaryLightColor;
                    background-color: transparent;
                }
            }
        }
        @include screen('mobile'){
            .navbar-brand>img{
                max-height: 24px;
            }
        }
    }
}

.btn{
    &.special{
        border: 2px solid transparent;
        border-radius: 50px;
        padding: 10px 50px;
        background: $primaryLightColor;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        transition: all 300ms ease;
        &:focus,&:active{
            background-color: $primaryColor;
            border-color: $primaryColor;
            outline: none;
        }
    }
}

#home-slider {
    height: 400px;
    margin-bottom: 80px;
    overflow: hidden;
    position: relative;
    margin-top: -50px;
    .slider-area {
        height: 400px;
        .background-img {
            background-position: top center;
            background-size: cover;
            height: 400px;
            position: relative;
        }
        .content {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            .text-container {
                height: 400px;
                position: relative;
                .text {
                    width: 100%;
                    @include center(vertical);
                    h1 {
                        font-size: 56px;
                        color: #fff;
                        font-weight: 400;
                        margin-bottom: 60px;
                        position: relative;
                        b{
                            font-weight: 600;
                        }
                    }
                    .links {
                        a {
                            border: 2px solid transparent;
                            border-radius: 50px;
                            display: inline-block;
                            padding: 10px 50px;
                            background: $primaryLightColor;
                            color: #fff;
                            text-transform: uppercase;
                            font-size: 16px;
                            font-weight: 700;
                            transition: all 300ms ease;
                            &.nb {
                                border: 2px solid #fff;
                                background: transparent;
                                box-sizing: border-box;
                            }
                            &:hover {
                                background: #fff;
                                color: $primaryColor;
                            }
                            &:last-child {
                                margin: 0;
                            }
                            @include screen('mobile') {
                                font-size: 14px;
                                padding: 5px 10px;
                                margin: 0 10px 0 0;
                                height: auto;
                                line-height: inherit;
                            }
                            @include screen('tablet') {
                                font-size: 15px;
                                margin: 0 5px 0 0;
                                padding: 7px 13px;
                                height: auto;
                                line-height: inherit;
                            }
                            @include screen('laptop') {
                                font-size: 16px;
                                padding: 10px 15px;
                                height: auto;
                                line-height: inherit;
                            }
                        }
                    }
                }
                @include screen('mobile') {
                    height: 450px;
                }
            }
            @include screen('mobile') {
                top: 0;
            }
        }
        @include screen('laptop') {
            height: 600px;
            .background-img {}
            .content {
                .text-container {
                    height: 600px;
                }
                .text-container {
                    .text {
                        padding: 0 40px;
                        h1 {
                            font-size: 32px;
                        }
                    }
                }
            }
        }
        @include screen('tablet') {
            height: 600px;
            .background-img {}
            .content {
                .text-container {
                    height: 600px;
                }
                .text-container {
                    .text {
                        padding: 0 80px;
                        h1 {
                            font-size: 32px;
                        }
                        h3 {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        @include screen('mobile') {
            height: 450px;
            .content {
                .text-container {
                    .text {
                        h1 {
                            font-size: 24px;
                            line-height: 28px;
                            margin-bottom: 10px;
                            max-height: 90px;
                            overflow: hidden;
                            &:before {
                                display: none;
                                bottom: -20px;
                            }
                        }
                        h3 {
                            font-size: 14px;
                            line-height: 18px;
                            max-height: 90px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
    .owl-dots{
        margin-top: -40px;
        position: relative;
        .owl-dot{
            margin-right: 10px;
            span{
                margin:0;
                height: 15px;
                width: 15px;
            }
            &.active{
                span{
                    background-color: $primaryLightColor;
                }
            }
            &:last-child{margin-right: 0}
        }
    }
    @include screen('mobile') {
        height: 450px;
    }
    @include screen('tablet') {
        height: 700px;
    }
    @include screen('laptop') {
        height: 600px;
    }
}
.event-list {
    li {
        height: 100%;
        box-shadow: 0px 0px 5px rgba(51, 51, 51, 0.2);
        margin-bottom: 30px;

        .top {
            height: 100%;
            display: block;
            background-color: rgba(245, 245, 245, 0.6);

            .top-left {
                height: 170px;
                overflow: hidden;

                time {
                    height: 100%;
                    width: 50%;
                    text-align: center;
                    text-transform: uppercase;
                    display: block;
                    float: left;
                    color: #fff;
                    position: relative;

                    div {
                        position: relative;
                        @include center(both);

                        .day,
                        .month {
                            z-index: 5;
                        }

                        .day {
                            font-size: 56pt;
                            font-weight: 100;
                            line-height: 1;
                            display: block;
                        }

                        .month {
                            font-size: 24pt;
                            font-weight: 900;
                            line-height: 1;
                            display: block;
                        }

                        .year,
                        .time {
                            display: none;
                        }
                    }

                    &:before {
                        z-index: 0;
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background-color: #c52c66;
                    }
                }

                .picture {
                    width: 50%;
                    display: block;
                    float: right;
                }
            }

            .top-right {
                padding: 10px 0;

                .title {
                    font-size: 17pt;
                    font-weight: 700;
                    margin: 0 0 10px 0;
                }

                .info-list {
                    .item {
                        margin: 0 0 5px 0;

                        .info-title {}

                        .info-content {
                            font-weight: 700;
                        }

                        &:last-child {
                            margin: 0
                        }
                    }
                }

                @include screen('mobile') {
                    padding: 20px;
                }

                @include screen('tablet') {
                    padding: 20px;
                }

                @include screen('laptop') {
                    padding: 20px;
                }
            }

            .buttons {
                text-align: right;
                margin: 20px 0 0 0;

                a {
                    display: inline-block;
                    padding: 5px 20px;
                    background-color: #ddd;
                    border-bottom: 3px solid rgba(0, 0, 0, 0.1);
                    float: left;
                    margin: 0 10px 0 0;
                    font-weight: 700;
                    color: #222;
                    border-radius: 3px;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.2);
                        color: #000;
                        text-shadow: 1px 1px 1px #fff;
                    }

                    &:last-child {
                        margin: 0
                    }
                }
            }
        }

        .bottom {
            padding: 30px;

            p {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .map {
                width: 100%;
                height: 400px;
                border-radius: 4px;
                border: 1px solid #eee;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        @include screen('mobile') {}

        @include screen('tablet') {}

        @include screen('desktop') {}
    }
}
.company-lists{
    margin-bottom: 60px;
    .lists-container{
        .company-item{
            margin-bottom:  30px;
            .company-item-container{
                position: relative;
                .company-date{
                    position: absolute;
                    top:0;
                    display: inline-block;
                    right: 0;
                    color: #fff;
                    text-align: center;
                    .date {
                        font-weight: bold;
                        line-height: 40px;
                        font-size: 18px;
                        width: 60px;
                        height:40px;
                        display: block;
                        background-color: #303f9f;
                        }
                    .month {
                        line-height: 40px;
                        background-color: #ff5500;
                        font-size: 16px;
                        font-weight: bold;
                        width: 60px;
                        height: 40px;
                        display: block;
                    }
                }
                .company-picture{}
                .company-content{
                    border-left:1px solid #ddd;
                    border-bottom: 2px solid $primaryLightColor;
                    border-right: 1px solid #ddd;
                    padding: 10px;
                    .company-title{
                        overflow: hidden;
                        display: block;
                        height: 40px;
                        font-weight: 600;
                        font-size: 14px;
                        a{
                            transition: all 300ms ease;
                            color: $primaryColor;
                            &:hover{
                                color: $primaryLightColor;
                            }
                        }
                    }
                    .rating-cat{
                        margin-top: 10px;
                        overflow: hidden;
                        .rating{
                            display: inline-block;
                            float:left;
                            ul{
                                li{
                                    line-height: 26px;
                                    display: inline-block;
                                    i{
                                        font-size: 13px;
                                        line-height: 14px;
                                        color: $primaryLightColor;
                                    }
                                }
                            }
                        }
                        .cat{
                            display: inline-block;
                            float: right;
                            a{
                                transition:  all 300ms ease;
                                display: inline-block;
                                padding: 5px 10px;
                                border-radius: 40px;
                                font-size: 10px;
                                font-weight: 400;
                                color: $primaryDarkColor;
                                border:1px solid #ddd;
                                &:hover{
                                    color: $primaryDarkColor;
                                    background: #ddd;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

#footer{
    position: relative;
    background-color: $primaryColor;
    .scroll-up{
        cursor: pointer;
        z-index: 3;
        background-color: $primaryLightColor;
        position: absolute;
        color: #fff;
        font-size: 24px;
        border-radius: 3px;
        width: 42px;
        text-align: center;
        height: 40px;
        @include center(both);
        border: 1px solid transparent;
        transition: all 300ms ease;
        top:0;
        i{
            line-height: 34px;
        }
        &:hover{
            color: $primaryLightColor;
            border-color: $primaryLightColor;
            background-color: #fff;
        }
    }
    .top{
        padding: 60px 0;
        @include screen('mobile'){
            padding: 60px 30px;
            .item{
                margin-bottom: 20px;
                &:first-child{
                    margin-top: 20px;
                }
            }
        }
        @include screen('tablet'){
            padding: 60px 20px;

        }
        .logo{
            margin-bottom: 20px;

        }
        .about{
            color: #fff;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 20px;
        }
        ul.contact-area {
            li {
                font-weight: 400;
                margin-bottom: 20px;
                overflow: hidden;
                display: table;
                color: #fff;
                .icon {
                    display: table-cell;
                    width: 30px;
                    font-size: 16px;
                    vertical-align: middle;
                }
                .content {
                    display: table-cell;
                    a {
                        display: inline-block;
                        color: #fff;
                        transition: all 300ms ease;
                        &:hover {
                            color: $primaryLightColor;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0
                }
            }
        }

        @include screen('tablet'){
            text-align: center;
            .logo{
                text-align: center;
                a{
                    display: inline-block;
                }
            }
            .about{
                text-align: center;
            }
            ul.contact-area{
                display: inline-block;
            }
            ul.links, .footer-title {
                text-align: left;
            }

        }
        .footer-title {
            font-size: 18px;
            position: relative;
            font-weight: 600;
            color: #fff;
            text-transform: uppercase;
            margin-bottom: 60px;
            &:after {
                content: '';
                width: 80%;
                height: 2px;
                position: absolute;
                left: 0;
                bottom: -20px;
                background-color: #1f2b79;
            }
            @include screen('mobile'){
                margin-bottom: 30px;
            }
            @include screen('tablet'){
                margin-top: 30px;
            }
        }
        ul.links {
            margin-left: 20px;
            li {
                margin-bottom: 10px;
                a {
                    position: relative;
                    display: table;
                    width: 100%;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    color: #fff;
                    i,
                    span {
                        transition: all 300ms ease;
                    }
                    i {
                        display: table-cell;
                        width: 16px;
                        color: #1f2b79;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    span {
                        display: table-cell;
                    }
                    &:after{
                        content :'';
                        position: absolute;
                        left: -20px;
                        top: 5px;
                        width: 10px;
                        height: 10px;
                        background-color: #1f2b79;
                        transform: rotate(45deg);
                    }
                    &:hover {
                        i,
                        span {
                            color: $primaryLightColor;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .bottom{
        padding: 20px 0;
        background-color: $primaryLightColor;
        .social{
            li{
                float:left;
                margin-right: 10px;
                a{
                    color: #fff;
                    font-size: 18px;
                    transition:  all 30ms ease;
                    &:hover{
                        color: $primaryColor;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .copyright{
            color: #fff; text-align: right;
        }
        @include screen('mobile'){
            text-align: center;
            .social{
                display: inline-block;
            }
            .copyright{
                text-align: center;
                font-size: 12px;
            }
        }
    }
}