@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/montserrat/montserrat-v12-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Light'), local('Montserrat-Light'),
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat/montserrat-v12-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/montserrat/montserrat-v12-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/montserrat/montserrat-v12-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat/montserrat-v12-latin-ext_latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  }