@mixin screen($size) {
  $desktop: "(min-width: 1200px)";
  $laptop: "(min-width: 1024px) and (max-width: 1199px)";
  $tablet: "(min-width: 768px) and (max-width: 1023px)";
  $mobile: "(max-width: 767px)";
  @if $size == desktop {
    @media only screen and #{$desktop} {
      @content;
    }
  }
  @else if $size == laptop {
    @media only screen and #{$laptop} {
      @content;
    }
  }
  @else if $size == tablet {
    @media only screen and #{$tablet} {
      @content;
    }
  }
  @else if $size == mobile {
    @media only screen and #{$mobile} {
      @content;
    }
  }

  @else {
    @media only screen and #{$size} {
      @content;
    }
  }
}


.foo {
  margin: 0 auto;
  width: 100%;
  @include screen('tablet') {
    width: 90%;
  }

  @include screen('desktop') {
    width: 85%;
  }
}

//////////////


@mixin center($position) {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @else if $position == 'horizontal' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.foo {
  @include center(both);
}
.foo-parent {
  position: relative;
}

//////////////

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); //IE8
}
.foo {
  @include opacity(.4);
}

//////////////

@mixin retina($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* Serving 2x image on Retina display */
    background-image: url($image);
    background-size: $width $height;
  }
}
.foo {
  background-image: url("img/logo.png");
  @include retina("img/logo@2x.png", 100px, 21px);
}

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right);
@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
